<template>
	<div id="Judges">
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>专家评委会</p>
					<b>项目周期：2024年3月-2025年4月</b>
					<b>指导发起单位：中国初级卫生保健基金会</b>
				<b>执行单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="document">
			<el-tabs v-model="active">
				<el-tab-pane v-for="item in tabs" :key="item.k" :label="item.v" :name="item.k">
				</el-tab-pane>
			</el-tabs>
			<el-image v-if="active == 1" :src="Images.doctorPath" fit="cover">1</el-image>
			<el-image v-if="active == 2" :src="Images.mediumPath" fit="cover">2</el-image>
			<el-image v-if="active == 3" :src="Images.pressPath" fit="cover">3</el-image>
			<!-- <img alt="">
			<img v-if="active == 2" :src="banner.mediumPath" alt="">
			<img v-if="active == 3" :src="banner.pressPath" alt=""> -->
		</div>
	</div>
</template>

<script>
	import {
		getConfig, // 首页获取logo banner 流程图  
		getEvaluation // 获取评委会数据
	} from '@/request/api.js';
	export default {
		name: 'Judges',
		components: {},
		data() {
			return {
				banner: '',
				active: '1',
				Images: '',
				tabs: [{
					v: '专家/医生',
					k: '1'
				}, {
					v: '出版社代表',
					k: '2'
				}, {
					v: '媒体代表',
					k: '3'
				}]
			}
		},
		created() {
			this.getConfigFun();
			this.getEvaluationFun();
		},
		mounted() {},
		methods: {
			getEvaluationFun() {
				getEvaluation().then(res => {
					console.log(res)
					if (res.code == 200) {
						this.Images = res.data
					}
				})
			},

			// handleClick(tab, event) {
			// 	console.log(tab, event);
			// },

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#Judges {
		display: flex;
		flex-direction: column;

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.document {
			width: 1200px;
			margin: auto;
			background-color: #fcfcfc;
			min-height: 700px;
			display: flex;
			flex-direction: column;

			::v-deep .el-tabs {

				.el-tabs__nav-scroll {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.el-tabs__nav {
						flex: 1;
						display: flex;
						flex-direction: row;
						justify-content: center;

						.el-tabs__active-bar {
							display: none;
						}

						.el-tabs__item {
							font-size: 20px;
							padding: 10px 40px;
							height: 62px;
							flex: 1;
							text-align: center;
							transition: all .3s;
							box-sizing: border-box;
							border: 2px solid #e4e7ed;

						}

						.is-active {
							border: 2px solid #28B0A6;
							background-color: #28B0A6;
							color: #e4e7ed;
						}
					}
				}
			}

			.tabs {
				display: flex;
				flex-direction: row;



			}
		}
	}
</style>